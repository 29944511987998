import React from "react";
import Image from "./Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrows } from "@fortawesome/pro-light-svg-icons";

const GalleryImage = ({
  image,
  setSelectedImage,
  items,
  setNextImage,
  setPrevImage,
  currentImage,
  setCurrentImage,
}) => {
  return (
    <button
      className="relative group flex transition duration-150 hover:bg-teal"
      onClick={() => {
        setSelectedImage(image);
        setNextImage(items[currentImage + 1]);
        setPrevImage(items[currentImage - 1]);
        setCurrentImage(currentImage);
      }}
    >
      <div className="absolute top-0 left-0 z-10 w-full h-full place-items-center cursor-pointer grid opacity-0 transform scale-0 transition duration-200 group-hover:scale-100 group-hover:opacity-100">
        <div className="rounded-full w-14 h-14 bg-purple grid place-items-center">
          <FontAwesomeIcon icon={faExpandArrows} />
        </div>
      </div>

      <Image
        image={image}
        classes="h-full object-cover object-center group-hover:mix-blend-luminosity group-hover:opacity-50 transition duration-150"
      />
    </button>
  );
};

export default GalleryImage;
