import React, { useState } from "react";
import Layout from "../components/Layout";
import GalleryImage from "../components/GalleryImage";
import Modal from "../components/Modal";
import { graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThLarge,
  faRectangleLandscape,
} from "@fortawesome/pro-regular-svg-icons";

export const pageQuery = graphql`
  {
    wpPage(slug: { eq: "gallery" }) {
      id
      title
      pageGallery {
        gallery {
          ...MediaItem
        }
      }
      pageLinks {
        pageLinks {
          ... on WpPage {
            id
            title
            uri
          }
        }
      }
    }
  }
`;

const GalleryPage = ({ data }) => {
  const gallery = data.wpPage;
  const galleryItems = gallery.pageGallery.gallery;

  const firstImage = galleryItems[0];

  const [selectedImage, setSelectedImage] = useState(null);
  const [nextImage, setNextImage] = useState(false);
  const [prevImage, setPrevImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  return (
    <Layout title={gallery.title} page={gallery}>
      <main>
        <div className="container mx-auto px-6 md:px-0">
          <div className="flex justify-between my-12 md:my-16">
            <h1 className="text-3xl lg:text-6xl font-bold">{gallery.title}</h1>

            <div className="hidden md:flex gap-4">
              <button
                aria-label="Carousel view"
                className={`flex gap-2 items-center cursor-pointer font-bold group ${
                  selectedImage ? "text-teal" : ""
                }`}
                onClick={() => {
                  setSelectedImage(firstImage);
                }}
              >
                <div
                  className={`w-10 h-10 bg-teal grid place-items-center rounded-full ${
                    selectedImage
                      ? "text-purple"
                      : "hover-hover:group-hover:bg-white hover-hover:group-hover:text-teal"
                  }`}
                >
                  <FontAwesomeIcon icon={faRectangleLandscape} />
                </div>
                Carousel
              </button>
              <button
                aria-label="Grid view"
                className={`flex gap-2 items-center cursor-pointer font-bold group ${
                  !selectedImage ? "text-teal" : ""
                }`}
                onClick={() => {
                  setSelectedImage("");
                }}
              >
                <div
                  className={`w-10 h-10 bg-teal grid place-items-center rounded-full ${
                    !selectedImage
                      ? "text-purple"
                      : "hover-hover:group-hover:bg-white hover-hover:group-hover:text-teal"
                  }`}
                >
                  <FontAwesomeIcon icon={faThLarge} />
                </div>
                Grid
              </button>
            </div>
          </div>

          <Modal
            items={galleryItems}
            currentImage={currentImage}
            setCurrentImage={setCurrentImage}
            nextImage={nextImage}
            setNextImage={setNextImage}
            prevImage={prevImage}
            setPrevImage={setPrevImage}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />

          {!selectedImage && (
            <div className="relative my-8 grid sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10">
              {galleryItems.map((item, i) => {
                return (
                  <GalleryImage
                    key={item.id}
                    image={item}
                    setSelectedImage={setSelectedImage}
                    items={galleryItems}
                    setNextImage={setNextImage}
                    setPrevImage={setPrevImage}
                    currentImage={i}
                    setCurrentImage={setCurrentImage}
                  />
                );
              })}
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default GalleryPage;
