import React from "react";
import Image from "../components/Image";
import Arrow from "../components/Arrow";

const Modal = ({
  selectedImage,
  setSelectedImage,
  items,
  nextImage,
  setNextImage,
  prevImage,
  setPrevImage,
  currentImage,
  setCurrentImage,
}) => {
  return (
    <>
      {selectedImage && (
        <div className="flex gap-8 items-center mb-12">
          <button
            className="relative z-10 cursor-pointer group"
            onClick={() => {
              setSelectedImage(
                items[currentImage === 0 ? items.length - 1 : currentImage - 1]
              );
              setNextImage(
                items[currentImage === 0 ? items.length - 1 : currentImage - 1]
              );
              setPrevImage(items[currentImage === 0 ? items.length - 1 : 0]);
              setCurrentImage(
                currentImage === 0 ? items.length - 1 : currentImage - 1
              );
            }}
          >
            <Arrow direction="left" theme="white" />
          </button>
          <Image
            image={selectedImage}
            classes="block"
            onClick={() => {
              setSelectedImage("");
            }}
          />
          <button
            className="relative z-10 cursor-pointer group"
            onClick={() => {
              setSelectedImage(
                items[currentImage === items.length - 1 ? 0 : currentImage + 1]
              );
              setNextImage(
                items[currentImage === items.length - 1 ? 0 : currentImage + 1]
              );
              setPrevImage(items[currentImage === 0 ? items.length - 1 : 0]);
              setCurrentImage(
                currentImage === items.length - 1 ? 0 : currentImage + 1
              );
            }}
          >
            <Arrow direction="right" theme="white" />
          </button>
        </div>
      )}
    </>
  );
};

export default Modal;
